
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import '../styles/globals.css';
import '../styles/styles.scss';
import { pageView, GTM_ID } from '../lib/gtm';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import Script from "next/script";
function MyApp({ Component, pageProps }) {
    const router = useRouter();
    useEffect(() => {
        router.events.on('routeChangeComplete', pageView);
        return () => {
            router.events.off('routeChangeComplete', pageView);
        };
    }, [router.events]);
    return (<>
            <Script id="gtag-base" strategy="afterInteractive" dangerouslySetInnerHTML={{
            __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM_ID}');
          `
        }}/>
            <Script strategy="afterInteractive" dangerouslySetInnerHTML={{
            __html: 'window.__lc = window.__lc || {};\n' +
                '    window.__lc.license = 16962813;\n' +
                '    ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can\'t use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))'
        }}/>
            <Script strategy="afterInteractive" dangerouslySetInnerHTML={{
            __html: 'function affiliatets(options = {}) {\n' +
                '\n' +
                '    var btag = \'\';\n' +
                '    var url = new URL(window.location.href);\n' +
                '\n' +
                '    let defaults = {\n' +
                '         btagName: \'btag\',\n' +
                '        btagInputClass: \'affiliatetsBtag\'\n' +
                '    };\n' +
                '    \n' +
                '    let settings = Object.assign({}, defaults, options);\n' +
                '\n' +
                '    function setCookie(cname, cvalue, exdays) {\n' +
                '        var d = new Date();\n' +
                '        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));\n' +
                '        var expires = "expires=" + d.toUTCString();\n' +
                '        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";\n' +
                '    }\n' +
                '\n' +
                '    function getCookie(cname) {\n' +
                '        var name = cname + "=";\n' +
                '        var decodedCookie = decodeURIComponent(document.cookie);\n' +
                '        var ca = decodedCookie.split(\';\');\n' +
                '        for (var i = 0; i < ca.length; i++) {\n' +
                '            var c = ca[i];\n' +
                '            while (c.charAt(0) == \' \') {\n' +
                '                c = c.substring(1);\n' +
                '            }\n' +
                '            if (c.indexOf(name) == 0) {\n' +
                '                return c.substring(name.length, c.length);\n' +
                '            }\n' +
                '        }\n' +
                '        return "";\n' +
                '    }\n' +
                '\n' +
                '    function getBtagParamFromUrl() {\n' +
                '        return url.searchParams.get(settings.btagName);\n' +
                '    }\n' +
                '\n' +
                '    function getBtagFromCookies() {\n' +
                '        return getCookie(settings.btagName);\n' +
                '    }\n' +
                '\n' +
                '    function getBtag() {\n' +
                '        btag = getBtagFromCookies();\n' +
                '\n' +
                '        if (!btag) {\n' +
                '            btag = getBtagParamFromUrl();\n' +
                '            if (btag) {\n' +
                '                setCookie(settings.btagName, btag, 365);\n' +
                '            }\n' +
                '        }\n' +
                '    }\n' +
                '\n' +
                '    getBtag();\n' +
                '\n' +
                '    var list = document.getElementsByClassName(settings.btagInputClass);\n' +
                '    var n;\n' +
                '    for (n = 0; n < list.length; ++n) {\n' +
                '        list[n].value = btag;\n' +
                '    }\n' +
                '\n' +
                '    console.log(btag);\n' +
                '\n' +
                '}\n' +
                'affiliatets()'
        }}/>
            <Component {...pageProps}/>
        </>);
}
const __Next_Translate__Page__1900814219e__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__1900814219e__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  